import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Newsletter from '../components/Newsletter.vue'
import Embarazada from '../components/Embarazada.vue'
import Valora from '../components/Valora.vue'
import Inscribete from '../components/Inscribete.vue'
import ClubLHS from '../components/ClubLHS.vue'
import RecoverPass from '../components/RecoverPass.vue'
import ClientViewer from '../components/ClientViewer.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login - La Habitación Saludable"
    }
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: Newsletter,
    meta: {
      title: "Newsletter - La Habitación Saludable"
    },
  },
  {
    path: '/embarazada',
    name: 'Embarazada',
    component: Embarazada,
    meta: {
      title: "Embarazada - La Habitación Saludable",
      metaTags: [
        {
          attribute: "property",
          name: 'og:title',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "property",
          name: 'og:site_name',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "name",
          name: 'twitter:title',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "itemprop",
          name: 'name',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "name",
          name: 'twitter:card',
          content: 'summary'
        },
        {
          attribute: "property",
          name: 'og:type',
          content: 'website'
        },
        {
          attribute: "name",
          name: 'description',
          content: 'Queremos felicitarte por tu nuevo bebé y lo celebramos contigo con un regalo para que sigas cuidándote.'
        },
        {
          attribute: "property",
          name: 'og:description',
          content: 'Queremos felicitarte por tu nuevo bebé y lo celebramos contigo con un regalo para que sigas cuidándote.'
        },
        {
          attribute: "name",
          name: 'twitter:description',
          content: 'Queremos felicitarte por tu nuevo bebé y lo celebramos contigo con un regalo para que sigas cuidándote.'
        },
        {
          attribute: "itemprop",
          name: 'description',
          content: 'Queremos felicitarte por tu nuevo bebé y lo celebramos contigo con un regalo para que sigas cuidándote.'
        },
        {
          attribute: "property",
          name: 'og:url',
          content: 'https://lahabitacionsaludable.inforcloud.es'
        },
        {
          attribute: "property",
          name: 'og:image',
          content: 'https://lahabitacionsaludable.inforcloud.es/fEmbarazada.jpg'
        },
        {
          attribute: "property",
          name: 'og:image:width',
          content: '300'
        },
        {
          attribute: "property",
          name: 'og:image:height',
          content: '169'
        },
        {
          attribute: "name",
          name: 'twitter:image:src',
          content: 'https://lahabitacionsaludable.inforcloud.es/fEmbarazada.jpg'
        },
        {
          attribute: "itemprop",
          name: 'image',
          content: 'https://lahabitacionsaludable.inforcloud.es/fEmbarazada.jpg'
        }
      ]
    },
  },
  {
    path: '/valora',
    name: 'Valora',
    component: Valora,
    meta: {
      title: "Valora - La Habitación Saludable",
      metaTags: [
        {
          attribute: "property",
          name: 'og:title',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "property",
          name: 'og:site_name',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "name",
          name: 'twitter:title',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "itemprop",
          name: 'name',
          content: 'La Habitación Saludable | VALORACIÓN'
        },
        {
          attribute: "name",
          name: 'twitter:card',
          content: 'summary'
        },
        {
          attribute: "property",
          name: 'og:type',
          content: 'website'
        },
        {
          attribute: "name",
          name: 'description',
          content: '¿Nos ayudas con una valoración del taller al que has asistido?'
        },
        {
          attribute: "property",
          name: 'og:description',
          content: '¿Nos ayudas con una valoración del taller al que has asistido?'
        },
        {
          attribute: "name",
          name: 'twitter:description',
          content: '¿Nos ayudas con una valoración del taller al que has asistido?'
        },
        {
          attribute: "itemprop",
          name: 'description',
          content: '¿Nos ayudas con una valoración del taller al que has asistido?'
        },
        {
          attribute: "property",
          name: 'og:url',
          content: 'https://lahabitacionsaludable.inforcloud.es'
        },
        {
          attribute: "property",
          name: 'og:image',
          content: ''
        },
        {
          attribute: "property",
          name: 'og:image:width',
          content: '300'
        },
        {
          attribute: "property",
          name: 'og:image:height',
          content: '169'
        },
        {
          attribute: "name",
          name: 'twitter:image:src',
          content: ''
        },
        {
          attribute: "itemprop",
          name: 'image',
          content: ''
        }
      ]
    },
  },
  {
    path: '/inscribete',
    name: 'Inscribete',
    component: Inscribete,
    meta: {
      title: "Inscríbete a nuestros talleres - La Habitación Saludable",
      metaTags: [
        {
          attribute: "property",
          name: 'og:title',
          content: 'La Habitación Saludable | INSCRÍBETE'
        },
        {
          attribute: "property",
          name: 'og:site_name',
          content: 'La Habitación Saludable | INSCRÍBETE'
        },
        {
          attribute: "name",
          name: 'twitter:title',
          content: 'La Habitación Saludable | INSCRÍBETE'
        },
        {
          attribute: "itemprop",
          name: 'name',
          content: 'La Habitación Saludable | INSCRÍBETE'
        },
        {
          attribute: "name",
          name: 'twitter:card',
          content: 'summary'
        },
        {
          attribute: "property",
          name: 'og:type',
          content: 'website'
        },
        {
          attribute: "name",
          name: 'description',
          content: 'Inscríbete a nuestros talleres'
        },
        {
          attribute: "property",
          name: 'og:description',
          content: 'Inscríbete a nuestros talleres'
        },
        {
          attribute: "name",
          name: 'twitter:description',
          content: 'Inscríbete a nuestros talleres'
        },
        {
          attribute: "itemprop",
          name: 'description',
          content: 'Inscríbete a nuestros talleres'
        },
        {
          attribute: "property",
          name: 'og:url',
          content: 'https://lahabitacionsaludable.inforcloud.es'
        },
        {
          attribute: "property",
          name: 'og:image',
          content: ''
        },
        {
          attribute: "property",
          name: 'og:image:width',
          content: '300'
        },
        {
          attribute: "property",
          name: 'og:image:height',
          content: '169'
        },
        {
          attribute: "name",
          name: 'twitter:image:src',
          content: ''
        },
        {
          attribute: "itemprop",
          name: 'image',
          content: ''
        }
      ]
    },
  },
  {
    path: '/clublhs',
    name: 'ClubLHS',
    component: ClubLHS,
    meta: {
      title: "ClubLHS - La Habitación Saludable",
      metaTags: [
        {
          attribute: "property",
          name: 'og:title',
          content: 'La Habitación Saludable | CLUB LHS'
        },
        {
          attribute: "property",
          name: 'og:site_name',
          content: 'La Habitación Saludable | CLUB LHS'
        },
        {
          attribute: "name",
          name: 'twitter:title',
          content: 'La Habitación Saludable | CLUB LHS'
        },
        {
          attribute: "itemprop",
          name: 'name',
          content: 'La Habitación Saludable | CLUB LHS'
        },
        {
          attribute: "name",
          name: 'twitter:card',
          content: 'summary'
        },
        {
          attribute: "property",
          name: 'og:type',
          content: 'website'
        },
        {
          attribute: "name",
          name: 'description',
          content: 'Entrar en el Club LHS tiene premio. Elige entre un recetario y una evaluación nutricional gratuita.'
        },
        {
          attribute: "property",
          name: 'og:description',
          content: 'Entrar en el Club LHS tiene premio. Elige entre un recetario y una evaluación nutricional gratuita.'
        },
        {
          attribute: "name",
          name: 'twitter:description',
          content: 'Entrar en el Club LHS tiene premio. Elige entre un recetario y una evaluación nutricional gratuita.'
        },
        {
          attribute: "itemprop",
          name: 'description',
          content: 'Entrar en el Club LHS tiene premio. Elige entre un recetario y una evaluación nutricional gratuita.'
        },
        {
          attribute: "property",
          name: 'og:url',
          content: 'https://lahabitacionsaludable.inforcloud.es'
        },
        {
          attribute: "property",
          name: 'og:image',
          content: 'https://lahabitacionsaludable.inforcloud.es/fClubLHS.jpg'
        },
        {
          attribute: "property",
          name: 'og:image:width',
          content: '300'
        },
        {
          attribute: "property",
          name: 'og:image:height',
          content: '169'
        },
        {
          attribute: "name",
          name: 'twitter:image:src',
          content: 'https://lahabitacionsaludable.inforcloud.es/fClubLHS.jpg'
        },
        {
          attribute: "itemprop",
          name: 'image',
          content: 'https://lahabitacionsaludable.inforcloud.es/fClubLHS.jpg'
        }
      ]
    },
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    component: RecoverPass,
    meta: {
      title: "Recuperar contraseña - La Habitación Saludable"
    },
  },
  {
    path: '/visor',
    name: 'ClientViewer',
    component: ClientViewer,
    meta: {
      title: "Visor de clientes - Unidad Elca"
    },
  },
  {
    path: '/inicio',
    name: 'Inicio',
    meta: {
      title: "Inicio - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/clientes',
    name: 'Clientes',
    meta: {
      title: "Clientes - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Clientes" */ '../views/Clientes.vue')
  },
  {
    path: '/citas',
    name: 'Citas',
    meta: {
      title: "Citas - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Citas" */ '../views/Citas.vue')
  },
  {
    path: '/talleres',
    name: 'Talleres',
    meta: {
      title: "Talleres - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Talleres" */ '../views/Talleres.vue')
  },
  {
    path: '/envios',
    name: 'Envios',
    meta: {
      title: "Envíos - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Envios.vue')
  },
  {
    path: '/avisos',
    name: 'Avisos',
    meta: {
      title: "Avisos - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Avisos" */ '../views/Avisos.vue')
  },
  {
    path: '/admin',
    name: 'Panel de administración',
    meta: {
      title: "Panel de administración - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Admin.vue')
  },
  {
    path: '/calendario',
    name: 'Calendario',
    meta: {
      title: "Calendario - La Habitación Saludable"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Calendario.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.meta.metaTags){
    Array.from(document.querySelectorAll('meta[property]')).map(el => el.parentNode.removeChild(el));
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    to.meta.metaTags.forEach(e => {
      const tag = document.createElement('meta');
      tag.setAttribute(e.attribute, e.name);
      tag.setAttribute("content", e.content);
      tag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(tag);
    });
  }
  if(to.name == 'RecoverPass' || to.name == 'Newsletter' || to.name == 'Embarazada' || to.name == 'ClubLHS' || to.name == 'Valora' || to.name == 'Inscribete'){
    next();
  }else{
    if (to.name !== 'Login' && (store.state.auth=='' || store.state.auth==undefined)){
      next({ name: 'Login' })
    }else{
      if((to.name == 'Panel de administración' && store.state.typeUser!='admin')||(to.name == 'Envios' && (store.state.typeUser!='admin' && store.state.typeUser!='coord'))){
        next({ name: 'Login' })
      }else{
        next()
      }
    }
  }
})

export default router
